import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import CareerTestimonials from "../components/Testimonials/CareerTestimonials"
import AOS from "aos"
import "aos/dist/aos.css"
import loadable from "@loadable/component"
import EmbedVideoFrame from "../components/EmbedVideoFrame"
import Helmet from "react-helmet"
import FAQ from "../components/FAQ"

const FAQContent = [
  {
    question: "1. How can I apply for a job in Vantage Circle?",
    answer: `Now that you are on our careers page please click on the suitable open positions above and click on <strong>APPLY FOR THIS JOB</strong> tab. It will lead you to a new page where you will be able to upload your resume and fill in a few details about yourself.<br/><br/>
        Click on the <strong>APPLY FOR THIS JOB</strong> tab and you are done.`,
  },
  {
    question: "2. Can I apply for more than one job?",
    answer: `You may not have to apply for more than one job as we will anyways redirect your CV in case we find it suitable for any other open positions that we are currently hiring for.`,
  },
  {
    question: "3. Why can’t I apply for the same job profile twice?",
    answer: `This is to avoid duplication of profiles. However if you have any doubt about the successful submission of your application, please feel free to write to <a href="mailto:careers@vantagecircle.com" className="">careers@vantagecircle.com</a> and we will make sure that your application is received by us.`,
  },
  {
    question: "4. What happens after I submit my application?",
    answer: `After you submit your application, the recruiting team will be checking the resumes, and based on the relevance with the job role, your application will be processed. If shortlisted, you will receive an email from us to continue for further process.`,
  },
  {
    question: "5. How much time does it take to process the applications?",
    answer: `Now that you are on our careers page please click on the suitable open positions above and click on <strong>APPLY FOR THIS JOB</strong> tab. It will lead you to a new page where you will be able to upload your resume and fill in a few details about yourself.<br/><br/>
        Click on the <strong>APPLY FOR THIS JOB</strong> tab and you are done.`,
  },
  {
    question:
      "6. How will I know if my application is shortlisted or rejected?",
    answer: `Our recruiters are trying their best to ensure a smooth and fast process. However, due to the large volume of applications and multiple openings in our organization, it might take up to 30 to 45 days to process the application.`,
  },
  {
    question: "7. Is remote working available in Vantage Circle?",
    answer: `We provide work from home as an option to meet the personal needs of an employee. However, we don’t offer permanent remote working.`,
  },
  {
    question: "8. How will I know the reason for rejecting my application?",
    answer: `Glad, you asked that. The applications are rejected based on the criteria that are set for the candidate we are looking for.<br/><br/>Your application can be rejected if your profile is not relevant to the job description, or you don't meet the given criteria required for the profile.<br/><br/>Also, the applications are rejected if you submit an incomplete profile or did not submit the resume.`,
  },
  {
    question: "9. How will I be updated about the jobs in Vantage Circle?",
    answer: `To stay updated about jobs in our organization, you need to check this page regularly. You can also stay updated about new profiles by following our <a target="blank" href="https://www.linkedin.com/company/vantage-circle/">LinkedIn</a> , <a target="blank" href="https://www.instagram.com/vantagecircle/">Instagram</a> or <a target="blank" href="https://www.facebook.com/vantagecircle">Facebook</a> page.<br/><br/>Also, we will soon start sending an update for any new relevant job posted on our career page over an email. Do check your inbox for that.`,
  },
]

const FAQContentString = JSON.stringify(FAQContent)

const CareerVC = ({ location }) => {
  const [link, setlink] = useState("")
  const [addClass, setaddClass] = useState("")
  const [marker, setmarker] = useState("")
  const [mobileBleep, setmobileBleep] = useState("")
  const [component, setcomponent] = useState("")
  const [socialLinks, setSocialLinks] = useState("")

  const handleClick = () => {
    setlink("https://www.youtube.com/embed/qPUI4H0nCLo")
  }
  const inViewport = elementID => {
    let elemID = document.querySelectorAll(`#${elementID}`)
    let boundingCareerPath = elemID[0].getBoundingClientRect()
    console.log(boundingCareerPath)
    if (
      boundingCareerPath.top >= 0 &&
      boundingCareerPath.left >= 0 &&
      boundingCareerPath.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      boundingCareerPath.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      return true
    } else return false
  }
  const topOfViewport = elementID => {
    let elemID = document.querySelectorAll(`#${elementID}`)
    let boundingCareerPath = elemID[0].getBoundingClientRect()
    if (boundingCareerPath.top < 0) {
      return true
    } else return false
  }
  const changePathState = () => {
    if (inViewport("careerPath")) {
      setaddClass("path")
    }
  }
  const changeMobilePathState = () => {
    if (topOfViewport("careerPath")) {
      setmobileBleep("bleep")
    }
  }
  const changeLocation = () => {
    console.log("hjhj", topOfViewport("ouroffices"))
    if (topOfViewport("ouroffices")) {
      setmarker("animateMarker")
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", changePathState)

    return () => {
      window.removeEventListener("scroll", changePathState)
    }
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", changeMobilePathState)

    return () => {
      window.removeEventListener("scroll", changeMobilePathState)
    }
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", changeLocation)

    return () => {
      window.removeEventListener("scroll", changeLocation)
    }
  }, [])
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  const images = [
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185050/vantagecircle/career/fixed/7.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185050/vantagecircle/career/fixed/8.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185049/vantagecircle/career/fixed/13.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185047/vantagecircle/career/fixed/1.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185048/vantagecircle/career/fixed/2.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185041/vantagecircle/career/fixed/3.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185039/vantagecircle/career/fixed/12.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185038/vantagecircle/career/fixed/6.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185037/vantagecircle/career/fixed/4.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185037/vantagecircle/career/fixed/10.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185035/vantagecircle/career/fixed/11.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185034/vantagecircle/career/fixed/5.png",
    "https://res.cloudinary.com/vantagecircle/image/upload/w_800/v1718185034/vantagecircle/career/fixed/9.png",
  ]

  const desktopLinks = (
    <div className="mx-auto w-full">
      <p className="font-bold text-gray-500 my-1 mx-auto w-full text-center">
        Follow us on:
      </p>
      <ul className="flex py-2 flex justify-center">
        <li className="pr-1">
          <a
            href="https://www.linkedin.com/company/vantage-circle/"
            target="_blank"
          >
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png"
              width="30"
            />
          </a>
        </li>
        <li className="pl-1">
          <a
            href="https://instagram.com/jobnlife_at_vantage?igshid=MzRlODBiNWFlZA=="
            target="_blank"
          >
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/instagram-1.png"
              width="30"
            />
          </a>
        </li>
      </ul>
    </div>
  )
  const mobileLinks = (
    <div className="absolute top-10 right-16">
      <p className="font-bold text-gray-500 my-1">Follow us on:</p>
      <ul className="flex py-2">
        <li className="pr-1">
          <a
            href="https://www.linkedin.com/company/vantage-circle/"
            target="_blank"
          >
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png"
              width="30"
            />
          </a>
        </li>
        <li className="pl-1">
          <a
            href="https://instagram.com/jobnlife_at_vantage?igshid=MzRlODBiNWFlZA=="
            target="_blank"
          >
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/instagram-1.png"
              width="30"
            />
          </a>
        </li>
      </ul>
    </div>
  )

  const checkWidth = () => {
    if (window !== "undefined") {
      if (window.innerWidth <= 1024) {
        const EventImages = loadable(() =>
          import("../components/Testimonials/EventImagesTestimonial")
        )
        setcomponent(<EventImages />)
      } else {
        const Theatre = loadable(() => import("../components/TheatreCarousel"))
        setcomponent(<Theatre content={images} />)
      }
    }
  }

  useEffect(() => {
    checkWidth()
  }, [])

  return (
    <Layout location={location}>
      <>
        <Helmet>
          <title>Careers | Vantage Circle</title>

          <meta
            name="description"
            content="Unlock your potential with Vantage Circle. Explore diverse roles, foster growth, and achieve your professional goals. Join us today!"
          />
          <meta property="twitter:title" content="Careers | Vantage Circle" />
          <meta name="robots" content="index" />

          <meta
            property="twitter:description"
            content="Unlock your potential with Vantage Circle. Explore diverse roles, foster growth, and achieve your professional goals. Join us today!"
          />

          <meta property="og:type" content="website" />

          <meta
            property="og:url"
            content="https://www.vantagecircle.com/careers/"
          />

          <meta property="og:site_name" content="Vantage Circle" />
          <meta property="og:title" content="Careers | Vantage Circle" />

          <meta
            property="og:description"
            content="Unlock your potential with Vantage Circle. Explore diverse roles, foster growth, and achieve your professional goals. Join us today!"
          />
          <link
            rel="canonical"
            href="https://www.vantagecircle.com/careers/"
          ></link>
        </Helmet>

        <section class="hero-career w-full flex justify-center items-center flex-col relative py-6 xl:py-28 text-center">
          <div className="lg:max-w-5xl px-3">
            <h2 class="text-purple-100 text-3xl lg:text-4xl xl:text-7xl md:max-w-xl lg:max-w-7xl py-5 brmobile">
              Every person has the power to make a{" "}
              <span className="text-orange">difference.</span>
              <br /> Let us help you unleash yours.
            </h2>
          </div>
          {/* <div className = "md:max-w-xl lg:max-w-4xl xl:max-w-3xl my-5 px-4"><p class="text-purple-100 text-lg xl:text-2xl px-3">If you are passionate about what you do, we'd love you to be a part of our journey.</p></div> */}
          <div class="grid justify-center gap-5 mb-5 lg:my-5 py-5 w-9/12 lg:max-w-xl">
            <a
              class="vc-new-white-border-btn-rounded-full text-white lato"
              href="https://recruitcareers.zappyhire.com/en/vantagecircle"
              target="_blank"
            >
              Explore openings
            </a>
          </div>
        </section>
        <section className="w-full flex justify-center items-center px-5 xl:px-0 bg-purple-100 overflow-x-hidden">
          <div className="lg:flex lg:max-w-7xl py-5 lg:py-0">
            <div className="lg:w-8/12 py-10 xl:pt-16 lg:pr-3">
              <h2 className="homepage-heading lg:text-left text-center">
                Recognized as a Great Place to Work for 4 consecutive years
              </h2>
              {/* <p className='py-9 text-xl text-center lg:text-left text-gray-500'>Great place to Work Certification recognizes employers who create an outstanding employee experience.</p> */}

              {/* <div className='w-full px-2 lg:px-0 pt-10'><a href= "https://www.vantagecircle.com/awards-and-reviews/" className='vc-btn-border-orange w-11/12 md:w-5/12 mx-auto lg:mx-0'>View our recognitions here</a></div> */}
            </div>
            <div className="lg:w-4/12 pb-0">
              <div className="grid md:flex justify-center transform -translate-x-2 my-5 lg:my-0 lg:-translate-x-0 lg:py-0 xl:pb-16">
                <div
                  data-aos="zoom-out"
                  data-aos-duration="200"
                  data-aos-delay="200"
                >
                  <img
                    className="md:mx-3 transform scale-90 lg:scale-75 origin-top opacity-50"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Great_place_to_work_23_24-1.png"
                    width="180"
                    height="50"
                  />
                </div>
                <div
                  data-aos="zoom-out"
                  data-aos-duration="500"
                  data-aos-delay="500"
                >
                  <img
                    loading="lazy"
                    className="transform scale-105 lg:scale-100 origin-top"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1722856308/vantagecircle/career/Certification_Badge_MAY_2024-2025_6.png"
                    width="180"
                    height="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full flex flex-col justify-center items-center py-10 xl:py-16 px-7 xl:px-0 dashboardRnR">
          <div className="max-w-7xl">
            <h2 className="homepage-heading lg:text-left text-center w-full">
              We lead by <span className="text-orange">example</span>
            </h2>
            <p className="text-gray-500 pt-9 text-lg lg:text-xl">
              Fostering a people-first work culture isn't just the talk - it's
              our everyday walk!
            </p>
            <p className="text-gray-500 pb-9 text-lg lg:text-xl">
              Employee engagement initiatives move in the right direction when
              employees are valued and respected for what they do and what they
              stand for. With an excellence-driven work culture, we make the
              office an exciting place to be.
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 pt-6 lg:pt-10">
              <div className="pt-3">
                <div
                  className="shadow-xl border-2 border-white rounded overflow-hidden cursor-pointer relative"
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-offset="200px"
                >
                  <img
                    className={link.length ? "hidden" : "block"}
                    onClick={handleClick}
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/01/career-page-vid-thumb-1.webp"
                    width="768px"
                  />
                  <div className="">
                    <iframe
                      className={link.length ? "block" : "hidden"}
                      src={link}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      width="768"
                      height="360"
                      title="What's it like to Work at Vantage Circle ? | Corporate Culture | Careers at Vantage Circle"
                      allowfullscreen
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="mb-2">
                  <div className="flex">
                    <h2 className="text-3xl xl:text-5xl font bold lg:text-left">
                      Our Mission
                    </h2>
                    <div className="lg:block w-1/3 border-b transform -translate-y-1/3 ml-5"></div>
                  </div>
                  <p className="py-8 text-lg lg:text-xl text-gray-500">
                    Provide the best employee engagement platform for companies
                    across the world through a relentless focus on product
                    development and missionary zeal on client success.
                  </p>
                </div>
                <div className="mt-2">
                  <div className="flex">
                    <h2 className="text-3xl xl:text-5xl font bold lg:text-left">
                      Our Vision
                    </h2>
                    <div className="lg:block w-1/3 border-b transform -translate-y-1/3 ml-5"></div>
                  </div>
                  <p className="py-8 text-lg lg:text-xl text-gray-500">
                    With technology as our foundation, our vision is to create a
                    world where every employee feels valued, engaged, and
                    inspired to contribute their best towards their
                    organization's success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className='w-full flex flex-col py-12 justify-center items-center lg:pb-5 px-5 xl:px-0 bg-white'>
            <h2 className='text-3xl lg:text-4xl xl:text-5xl font-bold text-center pb-5'>Our core <span className='text-orange'>values</span></h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10 mb-20 gap-10 max-w-7xl'>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow'>
                    <div className='corevalues cv1'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Continous learning</h3>
                        <p className='pt-3 text-gray-500'>We believe in upskilling and growth.</p>
                    </div>
                </div>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow' >
                    <div className='corevalues cv2'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Teamwork & Collaboration</h3>
                        <p className='pt-3 text-gray-500'>We strive for excellence together.</p>
                    </div>
                </div>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow'>
                    <div className='corevalues cv3'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Inclusive work culture</h3>
                        <p className='pt-3 text-gray-500'>We respect every individual.</p>
                    </div>
                </div>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow'>
                    <div className='corevalues cv4'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Integrity</h3>
                        <p className='pt-3 text-gray-500'>We practice honesty & strong moral ethics.</p>
                    </div>
                </div>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow'>
                    <div className='corevalues cv5'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Commitment</h3>
                        <p className='pt-3 text-gray-500'>We are committed to our communities.</p>
                    </div>
                </div>
                <div className='flex bg-gray-100 rounded-md border-2 border-white px-4 pb-0 pt-6 shadow'>
                    <div className='corevalues cv6'></div>
                    <div className='mx-4'>
                        <h3 className='text-xl'>Transparency</h3>
                        <p className='pt-3 text-gray-500'>We are open in our communication.</p>
                    </div>
                </div>
            </div>
        </section> */}

        <section className="py-10 xl:py-16">
          <div className="max-w-7xl mx-auto px-7">
            <h2 className="text-gray-250 homepage-heading max-w-3xl mx-auto mb-5 text-center">
              Our <span className="text-orange">core values</span>
            </h2>
            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5 xl:gap-16 xl:mt-20 xl:mb-10">
              <div
                className="my-5 flex-col justify-center items-center xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                <div className="w-full">
                  <img
                    className="py-5 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Career-Learning.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">Learning</h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  We continuously learn new skills and gain knowledge, adapt to
                  changing circumstances, and stay competitive.
                </p>
              </div>
              <div
                className="my-5 xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <div className="w-full">
                  <img
                    className="py-5 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-ownership.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">Ownership</h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  We take responsibility for our actions and hold ourselves
                  accountable for fulfilling our respective duties.
                </p>
              </div>
              <div
                className="my-5 xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="500"
              >
                <div className="w-full">
                  <img
                    className="py-5 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-Team-effort.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">Team effort</h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  We function as one unified team with a shared commitment to
                  achieving excellence towards a common goal.
                </p>
              </div>
              <div
                className="my-5 xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="600"
              >
                <div className="w-full xl:mt-6">
                  <img
                    className="py-5 xl:py-2 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-agility.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">Agile with a goal</h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  We're agile, adaptive, open to change and have a long term
                  vision in reaching our goals & objectives.
                </p>
              </div>
              <div
                className="my-5 xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="700"
              >
                <div className="w-full xl:mt-6">
                  <img
                    className="py-5 xl:py-2 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-wellness.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">Well-being</h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  From physical fitness to mental health, we believe in making
                  every aspect of well-being a way of life.
                </p>
              </div>
              <div
                className="my-5 xl:my-0 border rounded-lg px-6 py-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="800"
              >
                <div className="w-full xl:mt-6">
                  <img
                    className="py-5 xl:py-2 mx-auto"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/h_70/gatsbycms/uploads/2023/05/Careers-data-driven-data.webp"
                  />
                </div>
                <h2 className="py-2 text-2xl text-center">
                  Data-driven decisions
                </h2>
                <p className="py-2 text-center px-3 xl:px-0 text-gray-250">
                  We ensure that our decisions or actions are evidence-based,
                  free from personal biases or external pressure.
                </p>
              </div>
            </div>
            {/* <div className='w-full flex justify-center pb-10'><a href="#form" className='text-orange text-center'>Get our Checklist for Employee Rewards and Recognition now</a></div> */}
          </div>
        </section>
        <section className="w-full flex flex-col justify-center items-center py-10 xl:py-16 px-7 xl:px-0 dashboardRnR relative">
          <div className="max-w-7xl careerTestimonialCarousel">
            <h2 className="homepage-heading lg:text-left text-center w-full">
              <span className="text-orange">Life </span>at Vantage Circle
            </h2>
            <p className="text-gray-500 pt-9 text-lg lg:text-xl">
              Life at Vantage Circle is fun where work is a thrill ride!
              Together, we strive for excellence, empower each other, and foster
              a appreciation based work culture that inspires everyone to
              upskill and succeed.
            </p>
            <p className="text-gray-500 pb-3 text-lg lg:text-xl">
              With cutting-edge innovations, we're redefining the future of
              work.{" "}
              <a href="#jobs" className="text-orange">
                Join our team, today!
              </a>
            </p>
            <div className="max-w-7xl mx-auto">{component}</div>

            <div className="grid justify-center xl:absolute xl:top-10 xl:right-16">
              <p className="font-bold text-gray-500 my-1 mx-auto xl:mx-0">
                Follow us on:
              </p>
              <ul className="flex py-2 mx-auto xl:mx-0">
                <li className="pr-1">
                  <a
                    href="https://www.linkedin.com/company/vantage-circle/"
                    target="_blank"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png"
                      width="30"
                    />
                  </a>
                </li>
                <li className="pl-1">
                  <a
                    href="https://instagram.com/jobnlife_at_vantage?igshid=MzRlODBiNWFlZA=="
                    target="_blank"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/instagram-1.png"
                      width="30"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <section className='w-full h-auto flex flex-col justify-center py-10 xl:py-16 rewards-bg-1 px-6 relative'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='text-3xl lg:text-4xl xl:text-5xl font-bold lg:text-left text-center w-full'><span className='text-orange'>Life </span>at Vantage Circle</h2>
                <div className='lg:w-11/12'>
                    <p className='text-gray-500 pt-9 text-lg lg:text-xl'>Life at Vantage Circle is fun where work is a thrill ride! Together, we strive for excellence, empower each other, and foster an appreciation-based work culture that inspires everyone to upskill and succeed.</p>
                    <p className='text-gray-500 pb-3 text-lg lg:text-xl'>With cutting-edge innovations, we're redefining the future of work. <a href = "#jobs" className='text-orange underline decoration-solid'>Join our team, today!</a></p>
                </div>
                <div className='max-w-7xl mx-auto'>
                    {component}
                </div>
            </div>
        </section> */}
        <section>
          <EmbedVideoFrame
            videoLink={"https://www.youtube.com/embed/kt9UEmI3y9s"}
            title={"We are thriving and expanding"}
            thumbnail={
              "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/thumbnail-blockC-video.webp"
            }
          />
        </section>
        {/* <section className='w-full my-24 md:mb-0 xl:mt-24 px-2' id="msTeamVideo">
            <div className='max-w-7xl mx-auto'>
                <h2 className='text-center max-w-4xl mx-auto text-3xl xl:text-5xl'>We are thriving and expanding</h2>
                <div class="container my-16 md:mb-0 lg:my-16 msteamsVid">              
                    <div className='amazonpaidvideo mx-auto rounded-xl shadow-xl md:shadow-none xl:shadow-xl border border-white relative'>
                        <iframe className={(`youtube-videos absolute left-0 top-0 right-0 bottom-0 ` + ((videoLinkMSTeams.length > 0) ? `block` : `hidden`))} loading="lazy" width="560" height="500" src={videoLinkMSTeams} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                        <img className= {(`absolute left-0 top-0 transform xl:-translate-y-12 right-0 bottom-0 h-52 md:h-auto md:w-10/12 xl:w-full mx-auto ` + ((videoLinkMSTeams.length > 0) ? `hidden` : `block`))} onClick={handleVideo} src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/play-btn-1.webp" />
                    </div>
                </div>
            </div>
        </section> */}
        <section className="w-full flex flex-col justify-center items-center py-10 xl:py-16 px-7 xl:px-0 bg-purple-100">
          <div className="max-w-7xl">
            <h2 className="homepage-heading lg:text-left text-center w-full">
              Our <span className="text-orange">benefits </span>
            </h2>
            <div className="lg:w-11/12">
              <p className="text-gray-500 pt-9 pb-3 text-lg lg:text-xl">
                Employee experience is at the heart of everything we do! From
                diversity and inclusion initiatives to exciting events and
                programs, we're committed to creating a workplace where everyone
                can thrive and succeed.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10 gap-10 max-w-7xl">
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Culture of recognition</h3>
                  <p className="py-6 text-gray-500">
                    Employee appreciation is more than just words - it's a
                    culture we live and breathe every day. We recognize every
                    effort, celebrate as a team, and ensure everyone feels
                    valued.
                  </p>
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Work-life balance</h3>
                  <p className="py-6 text-gray-500">
                    We understand that work is just one part of your life. We
                    offer wellness programs with our wellness app,
                    <span className="font-bold"> Vantage Fit</span>, to support
                    your physical and mental wellness.
                  </p>
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Healthcare benefits</h3>
                  <p className="py-6 text-gray-500">
                    Healthy employees, happy workplace! We provide comprehensive
                    healthcare benefits to prioritize your well-being such as
                    timely health checkups, health insurance and more.
                  </p>
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Financial support</h3>
                  <p className="py-6 text-gray-500">
                    By providing financial assistance, we prioritize our
                    employees' financial needs as an integral part of our
                    workplace values.
                  </p>
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Innovation</h3>
                  <p className="py-6 text-gray-500">
                    Let's ignite your spark. If you have ideas that can bring a
                    change; we are game. We acknowledge innovative ideas and
                    mindset.
                  </p>
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5 py-9 h-72 md:h-80 lg:h-96 xl:h-72"
                data-aos="zoom-in"
              >
                <div className="w-1/12 flex justify-center items-start mt-1">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/career-tick.webp"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 w-11/12">
                  <h3 className="text-xl">Learning & career growth</h3>
                  <p className="py-6 text-gray-500">
                    We empower our employees to reach their full potential by
                    offering abundant opportunities for learning and career
                    growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="w-full flex flex-col justify-center items-center py-10 xl:py-16 px-7 xl:px-0"
          id="careerPath"
        >
          <div className="max-w-7xl">
            <h2 className="homepage-heading xl:text-left text-center w-full">
              {" "}
              The hiring process
            </h2>
            <div className="xl:w-7/12">
              <p className="text-gray-500 pt-5 pb-3 text-lg lg:text-xl text-center xl:text-left">
                Take the route to join us
              </p>
            </div>
            <div
              className={`hidden xl:grid grid-cols-5 mt-10 mb-20 xl:mb-10 gap-10 max-w-7xl relative ${addClass}`}
            >
              <div className="px-5" style={{ zIndex: "11" }}>
                <div
                  className="speechbubble-point border border-gray-150 rounded bg-purple-100 shadow p-6"
                  id="pop0"
                  style={{ background: "rgba(255, 252, 245, 0.35)" }}
                >
                  <p className="text-center m-0 text-gray-500">
                    Apply through the portal
                  </p>
                </div>
                <div className="flex justify-center">
                  <p id="dot-0" className="font-bold  text-5xl">
                    .
                  </p>
                </div>
              </div>
              <div className="px-5" style={{ zIndex: "11" }}>
                <div
                  className="speechbubble-point border border-gray-150 rounded bg-purple-100 shadow p-6"
                  id="pop1"
                  style={{ background: "rgba(255, 252, 245, 0.35)" }}
                >
                  <p className="text-center m-0 text-gray-500">
                    Assignment or Questionnaire
                  </p>
                </div>
                <div className="flex justify-center">
                  <p className="font-bold  text-5xl" id="dot-1">
                    .
                  </p>
                </div>
              </div>
              <div className="px-5" style={{ zIndex: "11" }}>
                <div
                  className="speechbubble-point border border-gray-150 rounded bg-purple-100 shadow p-6"
                  id="pop2"
                  style={{ background: "rgba(255, 252, 245, 0.35)" }}
                >
                  <p className="text-center m-0 text-gray-500">
                    HR <br />
                    Round
                  </p>
                </div>
                <div className="flex justify-center">
                  <p className="font-bold  text-5xl" id="dot-2">
                    .
                  </p>
                </div>
              </div>
              <div className="px-5" style={{ zIndex: "11" }}>
                <div
                  className="speechbubble-point border border-gray-150 rounded bg-purple-100 shadow p-6"
                  id="pop3"
                  style={{ background: "rgba(255, 252, 245, 0.35)" }}
                >
                  <p className="text-center m-0 text-gray-500">
                    Domain <br />
                    Round
                  </p>
                </div>
                <div className="flex justify-center">
                  <p className="font-bold  text-5xl" id="dot-3">
                    .
                  </p>
                </div>
              </div>
              <div className="px-5" style={{ zIndex: "11" }}>
                <div
                  className="speechbubble-point border border-gray-150 rounded bg-purple-100 shadow p-6"
                  id="pop4"
                  style={{ background: "rgba(255, 252, 245, 0.35)" }}
                >
                  <p className="text-center m-0 text-gray-500">
                    Final <br />
                    Round
                  </p>
                </div>
                <div className="flex justify-center">
                  <p className="font-bold  text-5xl" id="dot-4">
                    .
                  </p>
                </div>
              </div>
              <div className="rounded-full w-full pt-5 bg-gray-150 absolute bottom-6 z-0"></div>
              <div className="rounded-full pt-5 bg-indigo-100 absolute bottom-6 z-10 purpleProgressPath"></div>
              <div
                className="rounded-full w-full pt-5 absolute bottom-0 grid grid-cols-8"
                style={{ zIndex: "11" }}
              >
                <div className=""></div>
                <div className="flex justify-center">
                  <p className="mx-auto text-gray-150">&gt;</p>
                </div>
                <div className=""></div>
                <div className="">
                  <p className="pl-5 text-gray-150">&gt;</p>
                </div>
                <div className="flex justify-end">
                  <p className="pr-5 text-gray-150">&gt;</p>
                </div>
                <div className="flex justify-center"></div>
                <div className="flex justify-center">
                  <p className="mx-auto text-gray-150">&gt;</p>
                </div>
                <div className="flex justify-center"></div>
              </div>
            </div>
            <div
              className={`my-10 relative ${mobileBleep} grid xl:hidden`}
              id="mobileCareerPath"
            >
              <div className="flex justify-center items-center bg-white xl:hidden z-10 relative">
                <p
                  className="border rounded flex items-center justify-center m-0 w-1/2 p-4 text-center"
                  id="bleep1"
                  style={{
                    height: "95px",
                    background: "rgba(255, 252, 245, 0.35)",
                  }}
                >
                  Apply through the portal
                </p>
              </div>
              <div
                className="my-5 text-center text-white relative pl-1 md:pl-0"
                style={{ zIndex: "11" }}
              >
                &darr;
              </div>
              <div className="flex justify-center items-center bg-white xl:hidden z-10 relative">
                <p
                  className="border rounded flex items-center justify-center m-0 w-1/2 p-4 text-center"
                  id="bleep2"
                  style={{
                    height: "95px",
                    background: "rgba(255, 252, 245, 0.35)",
                  }}
                >
                  Assignment or Questionnaire
                </p>
              </div>
              <div
                className="my-5 text-center text-white relative pl-1 md:pl-0"
                style={{ zIndex: "11" }}
              >
                &darr;
              </div>
              <div className="flex justify-center items-center bg-white xl:hidden z-10 relative">
                <p
                  className="border rounded flex items-center justify-center m-0 w-1/2 p-4 text-center"
                  id="bleep3"
                  style={{
                    height: "95px",
                    background: "rgba(255, 252, 245, 0.35)",
                  }}
                >
                  HR Round
                </p>
              </div>
              <div
                className="my-5 text-center text-white relative pl-1 md:pl-0"
                style={{ zIndex: "11" }}
              >
                &darr;
              </div>
              <div className="flex justify-center items-center bg-white xl:hidden z-10 relative">
                <p
                  className="border rounded flex items-center justify-center m-0 w-1/2 p-4 text-center"
                  id="bleep4"
                  style={{
                    height: "95px",
                    background: "rgba(255, 252, 245, 0.35)",
                  }}
                >
                  Domain Round
                </p>
              </div>
              <div
                className="my-5 text-center text-white relative pl-1 md:pl-0"
                style={{ zIndex: "11" }}
              >
                &darr;
              </div>
              <div className="flex justify-center items-center bg-white xl:hidden z-10 relative">
                <p
                  className="border rounded flex items-center justify-center m-0 w-1/2 p-4 text-center"
                  id="bleep5"
                  style={{
                    height: "95px",
                    background: "rgba(255, 252, 245, 0.35)",
                  }}
                >
                  Final Round
                </p>
              </div>
              <div
                className="rounded-full bg-indigo-100 absolute top-0 z-0 downProgress xl:hidden"
                style={{ left: "47.6%" }}
              ></div>
            </div>
          </div>

          <div class="grid justify-center gap-5 my-5 xl:my-0 py-5 xl:pt-0 w-9/12 lg:max-w-xl">
            <a class="vc-new-orange-btn-rounded-full lato" href="#jobs">
              Explore openings
            </a>
          </div>
        </section>
        <section className="w-full flex flex-col justify-center items-center py-10 xl:py-16 px-7 xl:px-0 dashboardRnR relative">
          <div className="max-w-7xl careerTestimonialCarousel">
            <h2 className="homepage-heading text-center w-full max-w-5xl">
              Know what our<span className="text-orange"> employees </span>say
              about the work life at Vantage Circle
            </h2>
            <div>
              <CareerTestimonials />
            </div>
          </div>
        </section>
        <section className="w-full flex justify-center items-center py-10 xl:py-16 px-7 xl:px-0 overflow-x-hidden">
          <div className="max-w-7xl">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-sart w-full mb-0 lg:mb-8 relative">
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                <h2 class="text-gray-250 homepage-heading">
                  What is Vantage Circle?
                </h2>
                <h2 className="text-gray-250 homepage-heading">
                  {" "}
                  The all-in-one Employee Engagement Platform
                </h2>
                <p class="text-gray-250 text-lg xl:text-xl my-4 leading-8">
                  We empower companies of all sizes to cultivate a thriving
                  workplace culture through innovative HRTech solutions that
                  enhance employee rewards & recognition, feedback, perks, and
                  wellness initiatives.
                </p>
                <div className="w-10/12 xl:w-5/12 my-7">
                  <a
                    href="https://www.vantagecircle.com/"
                    target="_blank"
                    className="vc-new-orange-btn-rounded-full text-white cursor-pointer"
                  >
                    Get to know us better
                  </a>
                </div>
              </div>
              <div class="w-full flex justify-center items-center z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/07/reward-or-appreciate.png"
                />
              </div>
            </div>
          </div>
        </section>
        <section
          className={`w-full flex flex-col justify-center items-center pt-10 pt-10 xl:pt-16 2xl:py-16 px-0 ${marker} overflow-hidden`}
          id="ouroffices"
        >
          <h2 className="homepage-heading text-center w-full max-w-5xl mx-auto pb-10">
            We are present<span className="text-orange"> globally </span>
          </h2>
          <div className="pt-9 relative globeWidth 2xl:border border-2 border-white 2xl:shadow rounded-xl 2xl:bg-white">
            <img
              className="2xl:rounded-xl"
              src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/02/Location-marker-map-scaled.webp"
            />
            <div className="absolute locMark locMarkUS" id="US">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1631098362/gatsbycms/uploads/2023/02/Group-14.webp" />
            </div>
            <div className="absolute px-1 py-1 bg-white rounded locNameUS flex items-center justify-center">
              <p className="text-lg text-gray-500 m-0">Texas, US</p>
            </div>

            <div className="absolute locMark locMarkCAN" id="CAN">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1631098362/gatsbycms/uploads/2023/02/Group-15.webp" />
            </div>
            <div className="absolute px-1 py-1 bg-white rounded locNameCAN flex items-center justify-center">
              <p className="text-sm text-gray-500 m-0">Alberta, CAN</p>
            </div>

            <div className="absolute locMark locMarkNED" id="NED">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1631098362/gatsbycms/uploads/2023/02/Group-18.webp" />
            </div>
            <div className="absolute px-1 py-1 bg-white rounded locNameNED flex items-center justify-center">
              <p className="text-sm text-gray-500 m-0">Amstelveen, NED</p>
            </div>

            <div className="absolute locMark locMarkIND" id="IND">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1631098362/gatsbycms/uploads/2023/02/Group-19.webp" />
            </div>
            <div className="absolute px-1 py-1 bg-white rounded locNameIND flex items-center justify-center">
              <p className="text-sm text-gray-500 m-0">Guwahati, IND</p>
            </div>

            <div className="absolute locMark locMarkAUS" id="AUS">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1631098362/gatsbycms/uploads/2023/02/Group-20.webp" />
            </div>
            <div className="absolute px-1 py-1 bg-white rounded locNameAUS flex items-center justify-center">
              <p className="text-sm text-gray-500 m-0">New South Wales, AUS</p>
            </div>
          </div>
        </section>
        <section id="jobs" class="py-10 xl:py-16 bgExploreCareer">
          <div class="container max-w-7xl p-6">
            <div class="text-center grid place-content-center">
              <h2 class="homepage-heading text-purple-100">
                Are you the one we’re looking for?
              </h2>
              <p class="section-subtitle grid place-content-center pt-4">
                <a
                  className="vc-new-white-border-btn-rounded-full text-white"
                  href="https://recruitcareers.zappyhire.com/en/vantagecircle"
                  target="_blank"
                >
                  Explore openings
                </a>
              </p>
              {/* <iframe title="Kekahire" src="https://vantagecircle.kekahire.com/api/embedjobs/872abe36-d59b-41c6-8ec0-697f3b55936e" frameborder="0" height="530px" width="100%"></iframe> */}
              {/* <iframe title="Zappyhire" src="https://recruitcareers.zappyhire.com/en/vantagecircle" frameborder="0" height="800px" width="100%"></iframe> */}
            </div>
          </div>
        </section>

        <section id="faqSection" data-content={FAQContentString} />

        <section className="w-full flex justify-center pb-10 bg-gray-100">
          <div class="grid justify-center gap-5 xl:my-0 py-5 xl:pt-0 w-9/12 lg:max-w-xl">
            <a
              class="vc-new-orange-btn-rounded-full text-white lato"
              href="#jobs"
            >
              Explore openings
            </a>
          </div>
        </section>
      </>
    </Layout>
  )
}

export default CareerVC
