import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import ReactDOM from "react-dom";
import Trans from '../../components/Trans'

const CareerTestimonials = () => {
  return (
    <div>
        <CarouselProvider className="max-w-4xl relative mx-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={6}
        isPlaying= {true}
        interval={5000}
    >
        
        <Slider className="rounded-lg bg-white shadow-xl z-0 my-11 p-5 grid items-start lg:items-center min-h-800px h-auto md:min-h-500px md:h-600 lg:min-h-300px lg:h-80">
            <Slide index={0} className= "">

            <div className = "lg:flex bg-white rounded-lg p-5 items-center">
                <div className='lg:flex lg:items-start lg:mt-3'>
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        The work I do with Vantage Circle gives me the ability to profoundly impact corporations and people worldwide, ultimately solidifying a deep level of career purpose.  If you seek a purpose in your career and the opportunity to be part of a stable but exponentially growing organization, Vantage Circle may be exactly what you are looking for. I look forward to working with you and changing the world together!                        </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-2'>
                    <div class="flex justify-center mx-auto h-24 w-28 rounded-full transform scale-125"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/Derrek-Head-Shot.jpg" height="120" width="95" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Derrek Wenisch</p>
                        <p class="text-center text-gray-500 lg:w-full">Sr. Business Development Manager, Canada</p>
                    </div>
                </div>
                </div>
                {/* <div className = "grid lg:flex bg-white rounded-lg h-full lg:h-auto p-5 items-center">
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img className='mx-auto lg:mx-0' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "40" />
                        <p className='my-4 text-gray-500 text-center md:text-center'>
                            Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented.
                        </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7'>
                        <div class="flex justify-center"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Speaker-4.png" height="100" width="120" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-2 text-xl text-gray-500 font-bold">Shruthi Bopaiah</p>
                        <p class="text-center text-gray-500 lg:w-full">AVP and Head of Internal Communication, <span class="font-bold">Infosys</span></p>
                    </div>
                </div> */}

            </Slide>
            <Slide index={1} className= "">
            <div className = "lg:flex bg-white rounded-lg px-5 items-center">
                <div className='lg:flex items-center lg:mt-5'>
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        Vantage Circle is more than just a workplace - it's a community. Over the past 9 years, I've had the privilege of working with some of the brightest and most talented individuals in the industry. The company's values of transparency, teamwork, and innovation are not just buzzwords - they are embodied in everything we do. As a Project Manager | Quality Assurance, I can say with confidence that Vantage Circle is truly dedicated to delivering exceptional results for our clients and employees.  
                        </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-0'>
                        <div class="flex justify-center"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/50kb-k.jpg" height="100" width="120" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Kongkona Das</p>
                        <p class="text-center text-gray-500 lg:w-full">Project Manager | QA, India</p>
                    </div>
                </div>
            </div>
            </Slide>
            <Slide index={2} className= "">
            <div className = "lg:flex bg-white rounded-lg p-5 items-center">
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        As a member of this company for over 7 years, I can confidently say that this organization has been instrumental in providing me with countless opportunities to showcase my creativity and grow my skillset with the latest cutting-edge technologies. The company's commitment to innovation and excellence has been inspiring and has helped me to achieve my professional goals. I am proud to be part of this company and look forward to many more years of success together.
                        </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-0'>
                        <div class="flex justify-center"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/50k-s.jpg" height="100" width="120" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Subhendu Gogoi</p>
                        <p class="text-center text-gray-500 lg:w-full">Engineering Manager, India</p>
                    </div>
                </div>
            </Slide>
            <Slide index={3} className= "">
            <div className = "lg:flex bg-white rounded-lg p-5 items-center">
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        I have had an incredible journey throughout these 8 years at Vantage Circle - a place where I have grown together with the organization. The best part of working at Vantage Circle is its culture - it's open, transparent, and inclusive enough to encourage one to think out loud.</p>                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-0'>
                        <div class="flex justify-center"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/50kb-s.jpg" height="100" width="120" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Sagarika Dev Roy</p>
                        <p class="text-center text-gray-500 lg:w-full">Lead Software Engineer, India</p>
                    </div>
                </div>
            </Slide>
            <Slide index={4} className= "">
            <div className = "lg:flex bg-white rounded-lg p-5 items-center">
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        I started out at Vantage Circle as an intern and am now a full-time software engineer with two years of expertise in backend development. Vantage Circle's supportive and inclusive work atmosphere has aided my personal and professional development. The company's emphasis on innovation and staff development has enabled me to improve my abilities and contribute to the development of intriguing new products. I strongly recommend Vantage Circle to anyone looking for a vibrant and challenging work environment in the tech industry.                       </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-0'>
                    <div class="flex justify-center mx-auto h-24 w-28 rounded-full transform scale-125"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/50kb-p.jpg" height="120" width="95" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Priyakshee Mahanta</p>
                        <p class="text-center text-gray-500 lg:w-full">Software Engineer, India</p>
                    </div>
                </div>
            </Slide>
            <Slide index={5} className= "">
            <div className = "lg:flex bg-white rounded-lg p-5 items-center">
                <div className='lg:flex lg:items-start'>
                    <div className='grid justify-center items-center text-left w-full lg:w-8/12'>
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" width= "30" />
                        <p className='my-4 text-gray-500 text-base pr-3'>
                        I joined in Oct 2022 as Head of the newly launched Program Management vertical to design and run a strategically crucial relationship-strengthening program for our largest enterprise clients.
Knowing Partha, founder & CEO for a considerably long time, it was a pretty straightforward decision for me, when I was approached. True to my belief, I have experienced that Vantage Circle truly fosters a culture of freedom, creativity, humility, inclusiveness, compassion and a lot more. Therefore I am extremely happy being here! 
                       </p>
                    </div>
                    <div className='grid justify-center items-center text-left w-full lg:w-4/12 lg:border-l border-gray-150 lg:pl-7 pt-10 lg:pt-3'>
                        <div class="flex justify-center mx-auto h-24 w-28 rounded-full transform scale-125"><img class="rounded-full border-2 border-purple-100" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/04/atif-square-F9DF-4DF3-9674-A303B20D3030-1.png" height="120" width="95" alt="speaker name" /></div>                        
                        <p class="text-center pt-5 pb-0 mb-1 text-xl text-gray-500 font-bold">Atif Ayubi</p>
                        <p class="text-center text-gray-500 lg:w-full">Director- Program Management, India</p>
                    </div>
                </div>
                </div>
            </Slide>
        </Slider>
        {/* <div className="w-full flex justify-center items-center my-9">
            <ButtonBack className="shadow eb-carousel-btn rounded-full p-4 mx-3">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id= "prev" d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
            </ButtonBack>
            <ButtonNext className="shadow eb-carousel-btn rounded-full p-4 mx-3">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="next" d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
            </ButtonNext>
        </div> */}
        <div className="w-full flex justify-center items-center">
            <DotGroup className = "mx-auto py-5" id = "dots"/>
        </div>
        </CarouselProvider>
    </div>
  )
}

export default CareerTestimonials